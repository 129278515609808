// import { FaCar, FaHandshake, FaCaravan } from "react-icons/fa6";
import { FaDumpsterFire } from "react-icons/fa";

export const links = [
{
  label: 'Classic & Exotic Cars',
  href: '/jjbest/classic-car-financing/no-nav',
  // icon: <FaCar />,
},
{
  label: 'Boats of Any Age',
  href: '/jjbest/boat-financing/no-nav',
  // icon: <FaCar />,
},
{
  label: 'Aircraft, RVs & Coins',
  href: '/jjbest/classic-car-financing',
  // icon: <FaCar />,
},
// {
//   label: 'Dealer Connect',
//   href: '/jjbest/classic-car-financing',
//   // icon: <FaCar />,
// },
{
  label: 'About Us',
  href: '/jjbest/classic-car-financing',
  // icon: <FaCar />,
  children: [
    {
      label: 'Financing Tips',
      href: '/jjbest/classic-car-financing',
      icon: <FaDumpsterFire />
    },
    // {
    //   label: 'Dealer Connect',
    //   href: '/jjbest/classic-car-financing',
    //   icon: <FaHandshake />
    // },
    {
      label: 'FAQ',
      href: '/jjbest/classic-car-financing',
      icon: <FaDumpsterFire />
    },
]
},
{
  label: 'Dealer Connect',
  href: '/jjbest/classic-car-financing',
  // icon: <FaCar />,
},
]
