import {
  Link,
  Box,
  Flex,
  HStack,
  Button,
  Text,
  // useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
// import HeroLogo from './HeroLogo'
// import Logo from './Logo';
import { NavLink } from "./NavLink";
import { NavMenu } from "./NavMenu";
import { Submenu } from "./Submenu";
import { ToggleButton } from "./ToggleButton";
import { links } from "../../data/_jjbest_navData";

const MobileNavContext = (props) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className="nav-content__mobile"
        {...props}
      >
        <Box>
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
       
        <Box
          visibility={{
            base: "hidden",
            sm: "visible",
          }}
        >
          <Link href="/jjbest/application/app-start" >
            <Button
              as="a"
              href="#"
              bgColor="secondary.600"
              colorScheme="blue"
              px="8"
              rounded="full"
              size="sm"
              fontSize="sm"
              fontWeight="bold"
            >
              Quick Quote
            </Button>
          </Link>
        </Box>
      </Flex>
      <NavMenu animate={isOpen ? "open" : "closed"}>
        {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          )
        )}
        <Link href="/jjbest/application/app-start">
          <Button
            as="a"
            href="#"
            bgColor="secondary.700"
            colorScheme="blue"
            px="8"
            rounded="full"
            size="sm"
            fontSize="sm"
            fontWeight="bold"
          >
           Quick Quote
          </Button>
        </Link>
      </NavMenu>
    </>
  );
};

const DesktopNavContent = (props) => {
  return (
    <Flex
      className="nav-content__desktop"
      align="center"
      justify="space-between"
      {...props}
    >
      {/* <Link href="/jjbest/application/app-start" >
          <Box as="a" rel="home" mx="auto">
            <Text h="24px" color="primary.900" >JJ Best Logo</Text>
          </Box> 
       <Box rel="home" pb="2">
          <Logo h="6" iconcolor="blue.500" />
        </Box>
      </Link> */}
      <HStack
        as="ul"
        id="nav__primary-menu"
        aria-label="Main Menu"
        listStyleType="none"
      >
        {links.map((link, idx) => (
          <Box as="li" key={idx} id={`nav__menuitem-${idx}`}>
            {link.children ? (
              <Submenu.Desktop link={link} />
            ) : (
              <Link href={link.href} passHref>
                <NavLink.Desktop>{link.label}</NavLink.Desktop>
              </Link>
            )}
          </Box>
        ))}
      </HStack>
      <HStack spacing="8" minW="240px" justify="flex-end">
        {/* <Box
          color={mode("blue.600", "blue.300")}
          fontWeight="bold"
        >
          Sign In
        </Box> */}
        {/* <Text>1-800-USA-1965</Text> */}
        <Link href="/jjbest/application/app-start">
          <Button
            bgColor="secondary.700"
            colorScheme="blue"
            px="8"
            rounded="full"
            size="sm"
            fontSize="sm"
            fontWeight="bold"
          >
            Quick Quote
          </Button>
        </Link>
      </HStack>
    </Flex>
  );
};

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
};
