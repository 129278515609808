/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        items {
          id
          applicationId
          interestRate
          loanAmount
          loanTerm
          monthlyPayment
          optionName
          totalInterest
          totalPayments
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicationGroupName
        applicationSource
        applicationName
        collateralType
        vehicleYear
        make
        model
        purchasePrice
        loanAmount
        firstName
        lastName
        applicationType
        cellPhone
        email
        ssn
        dob
        address
        city
        applicantState
        zipCode
        yearsAtResidence
        residenceType
        employmentStatus
        employer
        employmentPosition
        yearsInPosition
        yearlyIncome
        linkToImages
        disclaimerCheck
        insuranceInterest
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicationVersion = /* GraphQL */ `
  query GetApplicationVersion($id: ID!) {
    getApplicationVersion(id: $id) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApplicationVersions = /* GraphQL */ `
  query ListApplicationVersions(
    $filter: ModelApplicationVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        interestRate
        loanAmount
        loanTerm
        monthlyPayment
        optionName
        totalInterest
        totalPayments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const appsByAppId = /* GraphQL */ `
  query AppsByAppId(
    $applicationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    appsByAppId(
      applicationId: $applicationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicationId
        interestRate
        loanAmount
        loanTerm
        monthlyPayment
        optionName
        totalInterest
        totalPayments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
