import { useState } from 'react';
import {
  Button,
  Container,
  Heading,
  Input,
  VStack,
  SimpleGrid,
  Stack,
  Text,
  FormLabel,
  FormControl,
  Select,
  InputGroup,
  InputLeftElement,
  Box,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { formatter } from '../../utils/currencyFormatter';

const LoanCalculator = () => {
  const navigate = useNavigate();
  const [purchasePrice, setPurchasePrice] = useState('');
  const handlePurchasePriceChange = event =>
    setPurchasePrice(event.target.value);
  const [downpaymentAmount, setDownpaymentAmount] = useState('');
  const handleDownpaymentAmountChange = event =>
    setDownpaymentAmount(event.target.value);
  const [loanTerm, setLoanTerm] = useState('7');
  const handleLoanTermChange = event => setLoanTerm(event.target.value);
  const [interestRate, setInterestRate] = useState('9.38');
  const handleInterestRateChange = event => setInterestRate(event.target.value);
  const [totalInterest, setTotalInterest] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState('');
  const [totalPayment, setTotalPayment] = useState('');

  const loanTermOptions = [3, 4, 5, 6, 7, 8, 8, 10];

  async function handleCalculateLoan() {
    const tempPrincipal = parseFloat(purchasePrice - downpaymentAmount);
    const tempInterest = parseFloat(interestRate) / 100 / 12;
    const tempPayments = loanTerm * 12;
    // compute the monthly payment figure
    const powers = Math.pow(1 + tempInterest, tempPayments); //Math.pow computes powers
    const tempMonthlyPayment =
      (tempPrincipal * powers * tempInterest) / (powers - 1);
    if (isFinite(tempMonthlyPayment)) {
      setMonthlyPayment(tempMonthlyPayment.toFixed(2));
      setTotalPayment((tempMonthlyPayment * tempPayments).toFixed(2));
      setTotalInterest(
        (tempMonthlyPayment * tempPayments - tempPrincipal).toFixed(2)
      );
    }
  }

  async function handleAppStartNavigation() {
    const slug = purchasePrice + "-" + downpaymentAmount;
    navigate(`/jjbest/application/app-start/${slug}`)
  }

  return (
    <Container maxW={'8xl'} p="5">
      <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
        <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
          <Stack spacing={{ base: '4', md: '6' }}>
            <Heading size={{ base: 'md', md: 'xl' }}>Loan Calculator</Heading>
            <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
              If you’re thinking about buying a classic car or a boat of any
              age, we can help you estimate your monthly loan payment.
            </Text>
          </Stack>
          <Stack
            spacing="6"
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            <FormControl id="purchasePrice">
              <FormLabel>Purchase Price</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  placeholder="Enter purchase price"
                  type="number"
                  value={purchasePrice}
                  name="purchasePrice"
                  onChange={event => handlePurchasePriceChange(event)}
                />
              </InputGroup>
            </FormControl>
            <FormControl id="downpaymentAmount">
              <FormLabel>Down Payment Amount</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  $
                </InputLeftElement>
                <Input
                  type="number"
                  placeholder="Enter down payment"
                  value={downpaymentAmount}
                  name="downpaymentAmount"
                  onChange={event => handleDownpaymentAmountChange(event)}
                />
              </InputGroup>
            </FormControl>
          </Stack>
          <Stack
            spacing="6"
            direction={{
              base: 'column',
              md: 'row',
            }}
          >
            <FormControl id="loanTerm">
              <FormLabel>Loan Term Years</FormLabel>
              <Select
                placeholder="select years for loan"
                size="md"
                value={loanTerm}
                onChange={handleLoanTermChange}
              >
                {loanTermOptions.map((type, id) => (
                  <option key={id} type={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="interestRate">
              <FormLabel>Interest Rate</FormLabel>
              <Input
                type="number"
                placeholder="interst rate..."
                value={interestRate}
                name="interestRate"
                onChange={event => handleInterestRateChange(event)}
              />
            </FormControl>
          </Stack>
          <Stack spacing={3} direction="column" align="center">
            <Button
              mt="8"
              mb="8"
              bgColor="primary.900"
              colorScheme="blue"
              px="8"
              rounded="full"
              size="md"
              fontSize="md"
              fontWeight="bold"
              type="submit"
              onClick={handleCalculateLoan}
            >
              Calculate
            </Button>
          </Stack>
        </Stack>
        <Stack
          spacing={{ base: '4', md: '5' }}
          // bgColor="gray.100"
          textAlign="center"
        >
          <Heading size={{ base: 'sm', md: 'md' }} padding={2}>
            Estimated Monthly Payment
          </Heading>
          <Stack spacing="3" textAlign="center">
            <Text
              fontSize={{ base: 'xl', md: '5xl' }}
              color="secondary.700"
              maxW="3xl"
            >
              ${monthlyPayment}
            </Text>

            <Box as="section" py={{ base: '4', md: '8' }}>
              <Container maxW="3xl">
                <Box
                  bg="bg.surface"
                  boxShadow="sm"
                  borderRadius="lg"
                  p={{ base: '4', md: '6' }}
                  bgColor="gray.50"
                >
                  <Flex
                    spacing="6"
                    direction={{
                      base: 'column',
                      md: 'row',
                    }}
                  >
                    <VStack align="center">
                      <FormControl id="totalPayments">
                        <FormLabel>Total Payments</FormLabel>
                        <Text>{formatter.format(totalPayment)}</Text>
                      </FormControl>
                    </VStack>
                    <Spacer />
                    <VStack align="center">
                      <FormControl id="monthlyPayments">
                        <FormLabel>Total Interest</FormLabel>
                        <Text>{formatter.format(totalInterest)}</Text>
                      </FormControl>
                    </VStack>
                  </Flex>
                </Box>
              </Container>
            </Box>
            <Stack
              spacing="6"
              direction={{
                base: 'column',
                md: 'row',
              }}
              align="left"
            >
              <Text fontSize={{ base: 'md', md: 'lg' }} color="fg.muted">
                Get a quote now and start living your dreams. Approval only
                takes a few minutes.
              </Text>
            </Stack>

            <Stack spacing={3} direction="column" align="center">
              <Button
                mt="8"
                mb="8"
                bgColor="secondary.700"
                colorScheme="blue"
                px="8"
                rounded="full"
                size="md"
                fontSize="md"
                fontWeight="bold"
                type="submit"
                onClick={handleAppStartNavigation}
              >
                Quick Quote
              </Button>
            </Stack>

            {/* <Stack
            spacing="6"
            direction={{
              base: "column",
              md: "row",
            }}
          >
            <Text fontSize={{ base: "md", md: "lg" }} color="fg.muted">
            Experts are here to help you
            </Text>
          </Stack> */}
            {/* <Heading size={{ base: 'xl', md: '5xl' }} color="accent">
        {monthlyPayment}
      </Heading> */}
            {/* <Text fontSize="lg" fontWeight="medium" color="fg.muted">
        {label}
      </Text> */}
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
};

export default LoanCalculator;
