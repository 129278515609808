import React, { useEffect } from "react";
// import { Container, Flex, useBreakpointValue } from "@chakra-ui/react";
// import Sidebar from "../../../components/pageshell/Sidebar";
// import Navbar from "../../../components/pageshell/Navbar";
import JJBestHero from "../../../../components/jjbest/JJBestHero";
import JJBestContent from "../../../../components/jjbest/JJBestContent";
import LoanCalculator from "../../../../components/jjbest/LoanCalculator";
import JJBestFooter from "../../../../components/jjbest/JJBestFooter";
import JJBestFeatures from "../../../../components/jjbest/JJBestFeatures";
import LandingLayoutJJBest from "../../../../components/jjbest/LandingLayoutJJBest";
import protectedRoute from "../../../../utils/protectedRoute"


const JJBestNoNav = (props) => {
  const { userInfo } = props;
  // const isDesktop = useBreakpointValue({
  //   base: false,
  //   lg: true,
  // });

  useEffect(() => {
  }, [userInfo]);

  return ( <LandingLayoutJJBest>
          <JJBestHero />
          <JJBestContent />
          <JJBestFeatures />
          <LoanCalculator />
          <JJBestFooter />
        </LandingLayoutJJBest>
     
  );
};

export default protectedRoute(JJBestNoNav);
