import React, { useEffect } from 'react'
// import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom'
import LoginScreen from "../../components/auth/LoginScreen"

const Login = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  useEffect(() => {
    async function handleCheckUser(){
      if(userInfo){
        navigate("/dashboard")
      }
    }
    // handleCheckUser()
  }, [userInfo]);

  return (<>
      <LoginScreen userInfo={userInfo}/>
    </>
  )
}

export default Login