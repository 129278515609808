import { useEffect, useState } from "react";
import {
  Container,
  Text,
  Flex,
  Box,
  Stack,
  FormLabel,
  FormControl,
  Radio,
  RadioGroup,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { generateClient } from 'aws-amplify/api';
import { updateApplication, createApplicationVersion } from "../../../../../graphql/mutations"
import { getApplication } from "../../../../../graphql/queries"
import useStep from "../../../../../utils/useStep";
import { steps } from "../../../../../data/_jjbest_stepData";
import Step from "../../../../../widgets/Step";
import Sidebar from "../../../../../components/pageshell/Sidebar";
import Navbar from "../../../../../components/pageshell/Navbar";
import { gbb_options } from "../../../../../data/_jjbest_GBB";
import { calculateLoan } from "../../../../../utils/loanGbb";
import protectedRoute from "../../../../../utils/protectedRoute"

const SubmitApplication = (props) => {
  const { userInfo } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const [application, setApplication] = useState([])
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const [disclaimerCheck, setDisclaimerCheck] = useState("");
  const [insuranceInterest, setInsuranceInterest] = useState("");

  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 4,
  });

  useEffect(() => {
    async function handleFetchApplication(){
      if(userInfo){
        try{
          const client = generateClient({authMode: 'userPool'})
          let result = await client.graphql({
            query: getApplication,
            variables: { id: id }
          })
          setApplication(result.data.getApplication);
        } catch(err){
          console.log("error in handleFetchApplication: ", err);
        }
      }
    }
    handleFetchApplication()

  }, [userInfo, id]);

  async function handleCreateGBB() {
    if(application){
      let applicationVersionData = [];
      for(let i = 0; i <=gbb_options.length - 1; i++){
        let tempData = {};
        tempData = await calculateLoan(application.loanAmount, gbb_options[i].interestRate, gbb_options[i].term, application.id, gbb_options[i].optionName)
        applicationVersionData.push(tempData)
      }
      return applicationVersionData
    }
  }


  async function handleUpdateApplication() {
    const applicationData = {
      id: id,
      disclaimerCheck: disclaimerCheck,
      insuranceInterest: insuranceInterest,
    };

    try{
      const client = generateClient({authMode: 'userPool'})
      await client.graphql({
        query: updateApplication,
        variables: { input: applicationData }
      })
    } catch(err){
      console.log("error in updateApplication: ", err);
    }
  await handleCreateApplicationVersion()
  }

  async function handleCreateApplicationVersion(){
    let data = await handleCreateGBB()
    for(let i = 0; i <= data.length - 1; i++){
      try{
        let tempData = data[i];
        const client = generateClient({authMode: 'userPool'})
        await client.graphql({
        query: createApplicationVersion,
        variables: {input: tempData},
      })

      } catch(err){
        console.log("error in createApplication: ", err);
      }
    }
    navigate(`/jjbest/application/app-result/${id}`)
  }

  return userInfo === null || userInfo === undefined ? (
   <div></div>
  ) : (
    <Flex
      as="section"
      direction={{
        base: "column",
        lg: "row",
      }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? (
        <Sidebar userInfo={userInfo} />
      ) : (
        <Navbar userInfo={userInfo} />
      )}

      <Container flex="1" maxW={"8xl"}>
        <Stack
          spacing={{
            base: "8",
            lg: "6",
          }}
        >
          <Stack
            spacing="4"
            direction={{
              base: "column",
              lg: "row",
            }}
            justify="space-between"
            align={{
              base: "start",
              lg: "center",
            }}
          >
            <Text fontSize="lg" fontWeight="medium" color="secondary.700">
              Tell us about...
            </Text>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              lg: "6",
            }}
          >
            <Box bg="bg-surface">
              <Container maxW={"6xl"}>
                <Stack
                  spacing="0"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      cursor="pointer"
                      onClick={() => setStep(id)}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      isFirstStep={id === 0}
                      isLastStep={steps.length === id + 1}
                    />
                  ))}
                </Stack>
              </Container>
              <Container
                // spacing="5"
                py={{
                  base: "4",
                  md: "8",
                }}
                maxW={"6xl"}
              >
                <Stack
                  spacing="6"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  <FormControl id="disclaimerCheck">
                    <FormLabel>
                      I have read, understood and agreed with the following
                      disclaimer
                    </FormLabel>
                    <RadioGroup
                      onChange={setDisclaimerCheck}
                      value={disclaimerCheck}
                    >
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Stack>
                    </RadioGroup>
                    <Text>
                      I/We have read this application and certify that
                      everything I/We have stated is accurate. J.J. Best Banc &
                      Co. may provide such information set forth in the
                      application and any other information it receives to
                      another creditor for consideration. By submitting this
                      application, I/We give J.J. Best Banc & Co., its corporate
                      affiliates, or its assigns, the authorization to access
                      my/our credit reports, employment history, or any other
                      information sources. I/We understand that the information
                      I/We have provided is given to obtain credit from J.J.
                      Best Banc & Co. or its assignees, and that J.J. Best Banc
                      & Co. will retain this application whether or not it is
                      approved, except as otherwise prohibited by application
                      law or regulation.
                    </Text>
                  </FormControl>
                </Stack>
                <br />

                <Stack
                  spacing="6"
                  direction={{
                    base: "column",
                    md: "row",
                  }}
                >
                  <FormControl id="insuranceInterest">
                    <FormLabel>
                      I am interested in J.J. Best Banc & Co.&apos;s Classic Car
                      Insurance program
                    </FormLabel>
                    <RadioGroup
                      onChange={setInsuranceInterest}
                      value={insuranceInterest}
                    >
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Stack>

                <Stack spacing={3} direction="column" align="center">
                  <Button
                    mt="8"
                    mb="8"
                    bgColor="secondary.700"
                    colorScheme="blue"
                    px="8"
                    rounded="full"
                    size="md"
                    fontSize="md"
                    fontWeight="bold"
                    type="submit"
                    onClick={handleUpdateApplication}
                  >
                    Submit
                  </Button>
                </Stack>
                <Stack spacing={3} direction="column" align="center"></Stack>
              </Container>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Flex>
  );
};

export default protectedRoute(SubmitApplication);
