/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication(
    $input: DeleteApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    deleteApplication(input: $input, condition: $condition) {
      id
      applicationGroupName
      applicationSource
      applicationName
      collateralType
      vehicleYear
      make
      model
      purchasePrice
      loanAmount
      firstName
      lastName
      applicationType
      cellPhone
      email
      ssn
      dob
      address
      city
      applicantState
      zipCode
      yearsAtResidence
      residenceType
      employmentStatus
      employer
      employmentPosition
      yearsInPosition
      yearlyIncome
      linkToImages
      disclaimerCheck
      insuranceInterest
      applicationVersions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApplicationVersion = /* GraphQL */ `
  mutation CreateApplicationVersion(
    $input: CreateApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    createApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApplicationVersion = /* GraphQL */ `
  mutation UpdateApplicationVersion(
    $input: UpdateApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    updateApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApplicationVersion = /* GraphQL */ `
  mutation DeleteApplicationVersion(
    $input: DeleteApplicationVersionInput!
    $condition: ModelApplicationVersionConditionInput
  ) {
    deleteApplicationVersion(input: $input, condition: $condition) {
      id
      applicationId
      interestRate
      loanAmount
      loanTerm
      monthlyPayment
      optionName
      totalInterest
      totalPayments
      createdAt
      updatedAt
      __typename
    }
  }
`;
