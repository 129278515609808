import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import {
  ChakraProvider,
} from '@chakra-ui/react';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes  } from 'aws-amplify/auth';
import amplifyconfig from './amplifyconfiguration.json';
import customTheme from "./styles/theme.js"
import Home from "./pages/Home.jsx"
import Login from "./pages/auth/login.js"
import ForgotPassword from "./pages/auth/forgot-password.js"
import Dashboard from "./pages/Dashboad.js"
import Admin from './pages/admin/index.js'
import Custom404 from "./pages/404.js"
import JJBest from "./pages/jjbest/classic-car-financing/index.js"
import JJBestNoNav from "./pages/jjbest/classic-car-financing/no-nav/index.js"
import JJBestBoatFinancing from "./pages/jjbest/boat-financing/index.js"
import JJBestBoatFinancingNoNav from "./pages/jjbest/boat-financing/no-nav/index.js"
import DirectApplications from "./pages/jjbest/direct-apps/index.js"
import AppStart from "./pages/jjbest/application/app-start/index.js"
import AppStartSlug from './pages/jjbest/application/app-start/[slug]/index.js'
import Applicant from "./pages/jjbest/application/applicant/[id]/index.js";
import Employer from "./pages/jjbest/application/employment/[id]/index.js"
import Images from "./pages/jjbest/application/images/[id]/index.js";
import SubmitApplication from './pages/jjbest/application/submit/[id]/index.js'
import ApplicationResult from './pages/jjbest/application/app-result/[id]/index.js';
import ContactUs from './pages/jjbest/application/contact-us/[id]/index.js'
import CustomizeMyApp from './pages/jjbest/direct-apps/[id]/index.js'

Amplify.configure(amplifyconfig);
const existingConfig = Amplify.getConfig();

Amplify.configure({
  ...existingConfig,
  API: {
    ...existingConfig.API,
    REST: {
      ...existingConfig.API?.REST,
      "joe-niemer-backend-v2": {
        // headers: async () => ({
        //   Authorization: (
        //     await fetchAuthSession()).tokens?.idToken?.toString(),
        // }),
        endpoint:
          'https://4erbspjx54.execute-api.us-east-1.amazonaws.com/prod',
        region: 'us-east-1' // Optional
      }
    }
  }
});

const App = () => {
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    async function getUserAttributes () {
      try {
        let attributes = await fetchUserAttributes();
        setUserInfo(attributes)
      } catch (err) {
        console.log(err);
      }
    }
    // const gtmId = process.env.NEXT_PUBLIC_GTM_ID || "";
    // const tagManagerArgs = {
    //   gtmId
    // }
    // TagManager.initialize(tagManagerArgs)
    getUserAttributes()
  }, [])

  return (
    <ChakraProvider resetCSS theme={customTheme}>
        <Router>
          <main>
            <Routes>
              <Route exact path="/" element={<Home />}/>
              <Route exact path="/auth/login" element={<Login userInfo={userInfo}/>}/>
              <Route exact path="/auth/forgot-password" element={<ForgotPassword />}/>
              <Route exact path="/dashboard" element={<Dashboard userInfo={userInfo}/>}/>
              <Route exact path="/admin" element={<Admin userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/classic-car-financing" element={<JJBest userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/classic-car-financing/no-nav" element={<JJBestNoNav userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/boat-financing" element={<JJBestBoatFinancing userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/boat-financing/no-nav" element={<JJBestBoatFinancingNoNav userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/direct-apps" element={<DirectApplications userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/direct-apps/:id?" element={<CustomizeMyApp userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/app-start" element={<AppStart userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/app-start/:slug?" element={<AppStartSlug userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/applicant/:id?" element={<Applicant userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/employment/:id?" element={<Employer userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/images/:id?" element={<Images userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/submit/:id?" element={<SubmitApplication userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/app-result/:id?" element={<ApplicationResult userInfo={userInfo}/>}/>
              <Route exact path="/jjbest/application/contact-us/:id?" element={<ContactUs userInfo={userInfo}/>}/>
              
              {/* Finally, catch all unmatched routes */}
              <Route path="*" element={<Custom404 />} />
            </Routes>
          </main>
        </Router>
    </ChakraProvider>
  );
}

export default App;
